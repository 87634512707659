<template>
<div></div>

</template>

<script>
import dump from '../../util/dump';
export default{
    created(){
        dump.toRenovation();
        this.$router.go(-1)
    }
}
</script>